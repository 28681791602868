import { Link, Table, TableColumn } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';

interface TestEnvironmentApplication {
  application: string;
  authentication: string;
  link: string;
}

export const getApplications = (
  hostingEnvironment: 'azure' | 'onpremises',
  team: string,
): TestEnvironmentApplication[] => {
  let baseDomain = '';

  switch (hostingEnvironment) {
    case 'azure':
      baseDomain = `${team.toLowerCase()}.cloud.rr-wfm.com`;
      break;
    case 'onpremises':
      baseDomain = `${team.toLowerCase()}.zeus.corp`;
      break;
    default:
      return [];
  }

  return [
    {
      application: 'R&R',
      authentication: 'Username/password',
      link: `https://my.${baseDomain}/`,
    },
    {
      application: 'R&R (SSO)',
      authentication: 'Single Sign-On',
      link: `https://my.${baseDomain}/sso/rrintern`,
    },
    {
      application: 'Configuration Web',
      authentication: 'Single Sign-On',
      link: `https://services.${baseDomain}/ConfigurationWeb/`,
    },
    {
      application: 'A&A',
      authentication: 'Username/password (LastPass)',
      link: `https://portal.${baseDomain}/DeVries.ApplicationServicesWeb/`,
    },
  ];
};

export const TestEnvironmentTable = () => {
  const { entity } = useEntity();

  const columns: TableColumn<TestEnvironmentApplication>[] = [
    {
      title: 'Application',
      field: 'application',
    },
    {
      title: 'Authentication',
      field: 'authentication',
    },
    {
      title: 'Link',
      field: 'link',
      render: (row: Partial<TestEnvironmentApplication>) => {
        return <Link to={row.link || ''}>{row.link}</Link>;
      },
    },
  ];

  const hostingEnvironment =
    entity.metadata.annotations?.['rr-wfm.com/hosting-environment'];

  const isHostingEnvironment = (
    value?: string,
  ): value is 'azure' | 'onpremises' =>
    value === 'azure' || value === 'onpremises';

  if (isHostingEnvironment(hostingEnvironment)) {
    const teamName = entity.metadata.name;
    const applications = getApplications(hostingEnvironment, teamName);
    const title = hostingEnvironment === 'azure' ? 'Azure' : 'On-premises';

    return (
      <Table
        columns={columns}
        data={applications}
        title={title}
        options={{
          paging: false,
        }}
      />
    );
  }

  return (
    <Table
      columns={columns}
      data={[]}
      title={undefined}
      options={{
        paging: false,
      }}
    />
  );
};
