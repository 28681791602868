// @ts-nocheck
/* eslint-disable @backstage/no-undeclared-imports */

import {
    CostInsightsApi,
    ProductInsightsOptions,
    Alert
} from '@backstage-community/plugin-cost-insights';
import { Cost, Entity, Group, MetricData, Project } from '@backstage-community/plugin-cost-insights-common'
import { isGroupEntity, stringifyEntityRef } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi, getEntityRelations } from '@backstage/plugin-catalog-react';
import dateFormat from 'dateformat';

export class CostInsightsClient implements CostInsightsApi {
    identityApi: IdentityApi;
    catalogApi: CatalogApi;

    constructor({ identityApi, catalogApi }: { identityApi: IdentityApi, catalogApi: CatalogApi }) {
        this.identityApi = identityApi;
        this.catalogApi = catalogApi;
    }

    async getLastCompleteBillingDate(): Promise<string> {
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

        return dateFormat(yesterday, 'yyyy-mm-dd');
    }

    async getUserGroups(userId: string): Promise<Group[]> {
        return (
            await this.catalogApi.getEntities({
                filter: {
                    kind: 'Group',
                    ['relations.hasMember']: [`user:default/${userId}`],
                },
                fields: ['metadata.name', 'spec.profile.displayName'],
            })
        ).items.map(e => {
            return {
                id: `group:${e.metadata.name}`,
                name: e.spec?.profile?.displayName || e.metadata.name,
            };
        });
    }

    async getGroupProjects(group: string): Promise<Project[]> {
        return [];
    }

    async getAlerts(group: string): Promise<Alert[]> {
        return [];
    }

    async getDailyMetricData(
        metric: string,
        intervals: string,
    ): Promise<MetricData> {
        console.log('getDailyMetricData', metric, intervals);
        return {
            id: 'remove-me',
            format: 'number',
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
        };
    }

    async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
        console.log('getGroupDailyCost', group, intervals);
        return {
            id: 'remove-me',
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
        };
    }

    async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
        return {
            id: 'remove-me',
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
        };
    }

    async getCatalogEntityDailyCost(
        catalogEntityRef: string,
        intervals: string,
    ): Promise<Cost> {
        return {
            id: 'remove-me',
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
        };
    }

    async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
        return {
            id: 'remove-me',
            aggregation: [0, 0],
            change: {
                ratio: 0,
                amount: 0,
            },
            entities: {},
        };
    }
}